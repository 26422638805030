import { CustomerType } from "@/models/entities/customer-type.interface";
import { Customer } from "@/models/entities/customer.interface";
import { MeasureUnitTypesByProduct } from "@/models/entities/measureUnitTypes-byProduct.interface";
import { ProductPriceBook } from "@/models/entities/productPriceBook.interface";
import { ProductPriceBookToSave } from "@/models/utils/product-price-book-to-save.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "productpricebook";

class ProductPriceBooksService extends ApiService {
  getAllProductPriceBooks() {
    return this.getAll<ProductPriceBook[]>(CONTROLLER);
  }
  getProductPriceBookByID(id: number) {
    return this.getByID<number, ProductPriceBook>(CONTROLLER, id);
  }
  getCustomerTypesByProductPriceBookID(id: number) {
    return this.getAll<CustomerType[]>(`${CONTROLLER}/${id}/customertypes`);
  }
  getCustomersByProductPriceBookID(id: number) {
    return this.getAll<Customer[]>(`${CONTROLLER}/${id}/customers`);
  }
  getMeasureUnitTypesByProductPriceBookID(id: number) {
    return this.getAll<MeasureUnitTypesByProduct[]>(
      `${CONTROLLER}/${id}/measureunittypes`
    );
  }
  saveProductPriceBook(productPriceBooks: ProductPriceBookToSave) {
    return this.post<ProductPriceBookToSave, number>(
      CONTROLLER,
      productPriceBooks
    );
  }
  updateProductPriceBook(
    id: number,
    productPriceBooks: ProductPriceBookToSave
  ) {
    return this.updateById<number, ProductPriceBookToSave, void>(
      CONTROLLER,
      id,
      productPriceBooks
    );
  }
  updateProductPriceBookIsEnabled(id: number) {
    return this.update<number, void>(`${CONTROLLER}/${id}/toggle`, null);
  }
  updateProductPriceBookIsFavorite(id: number) {
    return this.update<number, void>(`${CONTROLLER}/${id}/favorite`, null);
  }
  deleteProductPriceBook(id: number) {
    return this.delete<number, void>(CONTROLLER, id);
  }
}

export const productPriceBooks = new ProductPriceBooksService();
