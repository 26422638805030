
import { DataTable } from "@/models/utils/datatable.interface";
import { Action } from "@/models/utils/table-action.interface";
import { CellType, Header } from "@/models/utils/header.interface";
import { Component, Vue } from "vue-property-decorator";
import ProductPriceBookForm from "./ProductPriceBookForm.vue";
import { productPriceBooks } from "@/services/api/productPriceBooks.service";
import { ProductPriceBook } from "@/models/entities/productPriceBook.interface";
import { CustomerType } from "@/models/entities/customer-type.interface";
import { MeasureUnitTypesByProduct } from "@/models/entities/measureUnitTypes-byProduct.interface";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import { customerTypes } from "@/services/api/customer-type.service";
import { products } from "@/services/api/products.service";
import { ProductPriceBookToSave } from "@/models/utils/product-price-book-to-save.interface";
import { Product } from "@/models/entities/product.interface";
import { Customer } from "@/models/entities/customer.interface";
import { customers } from "@/services/api/customers.service";
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component({ components: { ProductPriceBookForm } })
export default class ProductPriceBooks extends Vue {
  private search = "";
  private valid = false;
  private dialogDelete = false;
  private productPriceBookToDelete: number = null;
  private onDeleteMessage = "";
  private productPriceBookDialog = {
    show: false,
    title: "",
    icon: "",
    productPriceBook: {} as ProductPriceBook,
    customerTypes: [] as CustomerType[],
    manuallyManagedCustomers: [] as Customer[],
    measureUnitTypes: [] as MeasureUnitTypesByProduct[],
    products: [] as Product[],
    isEdit: false,
  };
  private productPriceBookDialogCheck = {
    productPriceBook: {} as ProductPriceBook,
    customerTypes: [] as CustomerType[],
    measureUnitTypes: [] as MeasureUnitTypesByProduct[],
  };
  private closeDialog = false;
  private headers: Header[] = [
    {
      text: "ID",
      align: "start",
      filterable: true,
      value: "id",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Nome",
      align: "start",
      filterable: true,
      value: "productPriceBookName",
      cellType: CellType.DEFAULT,
      isPrimary: true,
      class: "llDataTable_header black--text",
    },
    {
      text: "Prodotto",
      align: "start",
      filterable: true,
      value: "productName",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Categoria",
      align: "start",
      filterable: true,
      value: "categoryName",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Categorie abilitate",
      align: "start",
      filterable: true,
      value: "customerTypes",
      cellType: CellType.DEPENDENCYLIST,
      customProp: "customerTypeName",
      dependencyProp: "isPersonalized",
      dependencyPropValue: false,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Utenti abilitati",
      align: "start",
      filterable: true,
      value: "customers",
      cellType: CellType.DEPENDENCYLIST,
      customProp: "email",
      dependencyProp: "isPersonalized",
      dependencyPropValue: true,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Personalizzato",
      align: "start",
      filterable: true,
      value: "isPersonalized",
      cellType: CellType.BOOLEAN,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Azioni",
      align: "start",
      filterable: false,
      sortable: false,
      value: "actions",
      cellType: CellType.ACTION,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Stato",
      align: "start",
      filterable: false,
      sortable: false,
      value: "isEnabled",
      cellType: CellType.SWITCH,
      isPrimary: false,
      class: "llDataTable_header",
    },
  ];

  public items: DataTable<unknown> = {
    key: "id",
    loading: true,
    headers: this.headers,
    values: [],
    search: "",
    actions: [
      { id: Action.EDIT, name: "edit" },
      { id: Action.DELETE, name: "delete" },
    ],
  };

  async created(): Promise<void> {
    await this.getTableItems();
  }

  async getTableItems(): Promise<void> {
    overlayModule.showOverlay();
    const r = await productPriceBooks.getAllProductPriceBooks();
    this.items.loading = false;
    this.$set(this.items, "values", r.data);
  }

  private onSearch(value: string): void {
    this.$set(this.items, "search", value);
  }

  private async showProductPriceBookDialog(
    item: ProductPriceBook
  ): Promise<void> {
    this.$set(this.productPriceBookDialog, "show", true);
    this.$set(this.productPriceBookDialog, "icon", "mdi-tag");
    if (item) {
      this.$set(this.productPriceBookDialog, "title", "Modifica listino");
      this.$set(this.productPriceBookDialog, "isEdit", true);
      await Promise.all([
        this.getCustomerTypes(true, item.id),
        this.getManuallyManagedCustomers(true, item.id),
        this.getProducts(),
        this.getProductPriceBookByID(item.id),
        this.getEnabledMeasureUnitTypes(item.id),
      ]);
    } else {
      this.$set(this.productPriceBookDialog, "title", "Nuovo listino");
      this.$set(this.productPriceBookDialog, "isEdit", false);
      await Promise.all([
        this.getManuallyManagedCustomers(false, null),
        this.getCustomerTypes(false, null),
        this.getProducts(),
      ]);
      this.$set(
        this.productPriceBookDialog,
        "productPriceBook",
        {} as ProductPriceBook
      );
      this.$set(
        this.productPriceBookDialog.productPriceBook,
        "isEnabled",
        true
      );
      this.$set(
        this.productPriceBookDialog,
        "measureUnitTypes",
        [] as MeasureUnitTypesByProduct[]
      );
    }
  }

  private async getManuallyManagedCustomers(
    isEdit: boolean,
    id: number
  ): Promise<void> {
    overlayModule.showOverlay();
    const c = await customers.getAllManuallyManagedCustomers();
    this.$set(this.productPriceBookDialog, "manuallyManagedCustomers", c.data);
    if (isEdit) {
      await this.getCustomersByProductPriceBookID(id);
    } else {
      this.productPriceBookDialog.manuallyManagedCustomers.forEach(
        (customer) => (customer.isSelected = false)
      );
    }
  }

  private async getCustomerTypes(isEdit: boolean, id: number): Promise<void> {
    overlayModule.showOverlay();
    const ct = await customerTypes.getAllCustomerType();
    this.$set(this.productPriceBookDialog, "customerTypes", ct.data);
    if (isEdit) {
      await this.getEnabledCustomerTypes(id);
    } else {
      this.productPriceBookDialog.customerTypes.forEach(
        (el) => (el.isEnabled = false)
      );
    }
  }

  private async getProducts(): Promise<void> {
    overlayModule.showOverlay();
    const p = await products.getAllProducts();
    this.$set(this.productPriceBookDialog, "products", p.data);
  }

  private async getProductPriceBookByID(id: number): Promise<void> {
    overlayModule.showOverlay();
    const pp = await productPriceBooks.getProductPriceBookByID(id);
    this.$set(this.productPriceBookDialog, "productPriceBook", pp.data);
    this.productPriceBookDialogCheck.productPriceBook = Object.assign(
      {},
      pp.data
    );
  }

  private async getEnabledCustomerTypes(id: number): Promise<void> {
    overlayModule.showOverlay();
    const ct = await productPriceBooks.getCustomerTypesByProductPriceBookID(id);
    ct.data.forEach((el) => {
      const customerType = this.productPriceBookDialog.customerTypes.find(
        (c) => c.id == el.id
      );
      if (customerType) {
        this.$set(customerType, "isEnabled", true);
      }
    });
    this.productPriceBookDialogCheck.customerTypes = JSON.parse(
      JSON.stringify(this.productPriceBookDialog.customerTypes)
    );
  }

  private async getCustomersByProductPriceBookID(id: number): Promise<void> {
    overlayModule.showOverlay();
    const mmc = await productPriceBooks.getCustomersByProductPriceBookID(id);
    mmc.data.forEach((el) => {
      const customer =
        this.productPriceBookDialog.manuallyManagedCustomers.find(
          (c) => c.id == el.id
        );
      if (customer) {
        this.$set(customer, "isSelected", true);
      }
    });
  }

  private async getEnabledMeasureUnitTypes(id: number): Promise<void> {
    overlayModule.showOverlay();
    const mut = await productPriceBooks.getMeasureUnitTypesByProductPriceBookID(
      id
    );
    this.$set(this.productPriceBookDialog, "measureUnitTypes", mut.data);
    this.productPriceBookDialogCheck.measureUnitTypes = JSON.parse(
      JSON.stringify(mut.data)
    );
  }

  private closeProductPriceBookDialog(): void {
    this.closeDialog = false;
    this.$set(this.productPriceBookDialog, "show", false);
  }

  private onDelete(item: ProductPriceBook): void {
    this.productPriceBookToDelete = item.id;
    this.onDeleteMessage =
      "Sei sicuro di volere eliminare il listino " +
      item.productPriceBookName.toUpperCase() +
      " ?";
    this.dialogDelete = true;
  }

  private async deleteProductPriceBook(): Promise<void> {
    overlayModule.showOverlay();
    await productPriceBooks.deleteProductPriceBook(
      this.productPriceBookToDelete
    );
    this.getTableItems();
    this.dialogDelete = false;
    snackbarModule.showSnackbar({
      message: "Eliminazione avvenuta con successo",
      type: "success",
    });
    setTimeout(() => snackbarModule.hideSnackbar(), 2000);
  }

  private async saveProductPriceBookDialog(): Promise<void> {
    let productPriceBook = {} as ProductPriceBookToSave;
    this.$set(
      productPriceBook,
      "productID",
      this.productPriceBookDialog.productPriceBook.productID
        ? this.productPriceBookDialog.productPriceBook.productID
        : null
    );
    this.$set(
      productPriceBook,
      "productPriceBookName",
      this.productPriceBookDialog.productPriceBook.productPriceBookName
    );
    this.$set(
      productPriceBook,
      "isEnabled",
      this.productPriceBookDialog.productPriceBook.isEnabled
    );
    this.$set(
      productPriceBook,
      "measureUnitTypes",
      this.productPriceBookDialog.measureUnitTypes
    );
    this.$set(
      productPriceBook,
      "isPersonalized",
      this.productPriceBookDialog.productPriceBook.isPersonalized
    );
    let enabledCustomerTypesArr =
      this.productPriceBookDialog.customerTypes.filter(
        (el) => el.isEnabled == true
      );
    let enabledCustomerTypesId = [] as number[];
    if (enabledCustomerTypesArr.length) {
      enabledCustomerTypesArr.forEach((el) =>
        enabledCustomerTypesId.push(el.id)
      );
    }
    this.$set(productPriceBook, "customerTypes", enabledCustomerTypesId);
    let enabledCustomersArr =
      this.productPriceBookDialog.manuallyManagedCustomers.filter(
        (el) => el.isSelected == true
      );
    let enabledCustomersIds = [] as number[];
    if (enabledCustomersArr.length) {
      enabledCustomersArr.forEach((el) => enabledCustomersIds.push(el.id));
    }
    this.$set(productPriceBook, "customers", enabledCustomersIds);
    const isValid = (
      this.$refs.form as Vue & { validate: () => boolean }
    ).validate();
    this.valid = isValid;
    if (isValid) {
      if (this.productPriceBookDialog.productPriceBook.id) {
        overlayModule.showOverlay();
        await productPriceBooks.updateProductPriceBook(
          this.productPriceBookDialog.productPriceBook.id,
          productPriceBook
        );
      } else {
        overlayModule.showOverlay();
        await productPriceBooks.saveProductPriceBook(productPriceBook);
      }
      this.getTableItems();
      this.$set(this.productPriceBookDialog, "show", false);
    }
    snackbarModule.showSnackbar({
      message: "Salvataggio avvenuto con successo",
      type: "success",
    });
    setTimeout(() => snackbarModule.hideSnackbar(), 2000);
  }

  private async onSwitched(item: ProductPriceBook): Promise<void> {
    overlayModule.showOverlay();
    await productPriceBooks.updateProductPriceBookIsEnabled(item.id);
    this.getTableItems();
  }

  private onClose(): void {
    if (this.productPriceBookDialog.isEdit == false) {
      this.$set(this.productPriceBookDialog, "show", false);
    } else {
      if (
        this.productPriceBookDialog.productPriceBook.productPriceBookName !=
          this.productPriceBookDialogCheck.productPriceBook
            .productPriceBookName ||
        this.productPriceBookDialog.productPriceBook.isEnabled !=
          this.productPriceBookDialogCheck.productPriceBook.isEnabled
      ) {
        this.closeDialog = true;
      } else if (
        this.arrOfObjAreSame(
          this.productPriceBookDialog.measureUnitTypes,
          this.productPriceBookDialogCheck.measureUnitTypes,
          "measureUnitTypeID"
        )
      ) {
        this.closeDialog = true;
      } else if (
        this.arrOfObjAreSame(
          this.productPriceBookDialog.customerTypes,
          this.productPriceBookDialogCheck.customerTypes,
          "id"
        )
      ) {
        this.closeDialog = true;
      } else {
        this.$set(this.productPriceBookDialog, "show", false);
      }
    }
  }

  private arrOfObjAreSame(arr1: unknown[], arr2: unknown[], prop: string) {
    if (arr1.length != arr2.length) {
      return true;
    } else {
      const a = JSON.parse(JSON.stringify(arr1)).sort((x, y) =>
        x[prop] < y[prop] ? 1 : -1
      );
      const b = arr2.sort((x, y) => (x[prop] < y[prop] ? 1 : -1));
      return JSON.stringify(a) !== JSON.stringify(b);
    }
  }
}
