
import { CustomerType } from "@/models/entities/customer-type.interface";
import { MeasureUnitTypesByProduct } from "@/models/entities/measureUnitTypes-byProduct.interface";
import { MeasureUnitType } from "@/models/entities/measureUnitType.interface";
import { ProductPriceBook } from "@/models/entities/productPriceBook.interface";
import { measureUnitTypes } from "@/services/api/measureUnitTypes.service";
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { required } from "@/helpers/rules";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import { Product } from "@/models/entities/product.interface";
import { productPhoto } from "@/services/api/productPhoto.service";
import { ProductPhoto } from "@/models/entities/product-photo.interface";
import { Customer } from "@/models/entities/customer.interface";
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class ProductPriceBookForm extends Vue {
  /** Sets the form value */
  @Prop({ required: true }) private productPriceBook: ProductPriceBook;
  /** Sets if the form is edit */
  @Prop({ required: true }) private isEdit: boolean;
  /** Sets the form products value */
  @Prop({ required: true }) private products: Product[];
  /** Sets the manually managed customers */
  @PropSync("manuallyManagedCustomers")
  private _manuallyManagedCustomers: Customer[];
  /** Sets the customer types */
  @PropSync("customerTypes") private customerTypesArr: CustomerType[];
  /** Sets the measure unit types */
  @PropSync("measureUnitTypes")
  private measureUnitTypesArr: MeasureUnitTypesByProduct[];

  private headers = [
    {
      text: "Priorità",
      value: "isDefault",
      cellType: "checkbox",
      class: "llDataTable_header_um",
    },
    {
      text: "Unità di misura",
      value: "measureUnitTypeName",
      cellType: "default",
      class: "llDataTable_header_um",
    },
    {
      text: "Conversione",
      value: "conversionInKg",
      cellType: "conversionInKg",
      class: "llDataTable_header_um",
    },
    {
      text: "Prezzo standard",
      value: "price",
      cellType: "price",
      align: "center",
      class: "llDataTable_header_um",
    },
    {
      text: "Prezzo in promo",
      value: "priceInPromo",
      property: "isInPromo",
      cellType: "promoPrice",
      align: "center",
      class: "llDataTable_header_um",
    },
    {
      text: "Foto",
      value: "photoID",
      cellType: "photo",
      align: "center",
      class: "llDataTable_header_um",
    },
    {
      text: "Stato",
      align: "start",
      filterable: false,
      sortable: false,
      value: "isEnabled",
      cellType: "switch",
      class: "llDataTable_header_um",
    },
    {
      text: "Azioni",
      value: "actions",
      filterable: false,
      sortable: false,
      class: "llDataTable_header_um",
    },
  ];
  private dialogMeasureUnit = {
    show: false,
    title: "",
    allMeasureUnitTypesArr: [] as MeasureUnitType[],
    needsConversion: false,
    conversion: null,
    selectedMeasureUnitType: null,
    isEdit: false,
    price: null,
    priceInPromo: null,
    IsConversionShown: false,
    isEnabled: true,
    isInPromo: false,
    photos: [] as ProductPhoto[],
    urls: [] as string[],
    checks: [] as boolean[],
    button: "modifica",
  };
  private required = required;
  private valid = false;
  private deleteDialog = false;
  private onDeleteMessage = "";
  private measureUnitTypeToDelete = null;

  mounted(): void {
    this.$emit("validate");
  }

  private checkConversion(): void {
    if (this.dialogMeasureUnit.selectedMeasureUnitType) {
      this.dialogMeasureUnit.needsConversion =
        this.dialogMeasureUnit.allMeasureUnitTypesArr.find(
          (measure) =>
            measure.id == this.dialogMeasureUnit.selectedMeasureUnitType
        ).needsConversion;
    }
  }

  private onIsPersonalizedChanged(): void {
    if (this.productPriceBook.isPersonalized) {
      this.customerTypesArr.forEach(
        (customerType) => (customerType.isEnabled = false)
      );
      return;
    }
    this._manuallyManagedCustomers.forEach(
      (customer) => (customer.isSelected = false)
    );
  }

  private async showDialogMeasureUnit(
    isEdit: boolean,
    item: MeasureUnitTypesByProduct
  ): Promise<void> {
    overlayModule.showOverlay();
    const r = await measureUnitTypes.getAllMeasureUnitTypes();
    const photos = await productPhoto.getPhotoByProductId(
      this.productPriceBook.productID
    );
    this.$set(this.dialogMeasureUnit, "checks", [] as boolean[]);
    this.$set(this.dialogMeasureUnit, "photos", [] as ProductPhoto[]);
    this.$set(this.dialogMeasureUnit, "urls", [] as string[]);
    if (photos.data.length > 0)
      photos.data.forEach(async (photo: ProductPhoto) => {
        const apiPhoto = await productPhoto.getById(photo.id);
        if (apiPhoto.data) {
          if (item && photo.id == item.photoID) {
            this.dialogMeasureUnit.checks.push(true);
          } else {
            this.dialogMeasureUnit.checks.push(false);
          }
          this.dialogMeasureUnit.photos.push(photo);
          this.dialogMeasureUnit.urls.push(
            window.URL.createObjectURL(
              new Blob([new File([apiPhoto.data], "photo")], {
                type: "image/*",
              })
            )
          );
        }
      });
    this.$set(this.dialogMeasureUnit, "show", true);
    this.$set(this.dialogMeasureUnit, "isEdit", isEdit);
    if (isEdit) {
      this.$set(this.dialogMeasureUnit, "title", "Modifica unità di misura");
      this.$set(this.dialogMeasureUnit, "button", "modifica");
      this.$set(this.dialogMeasureUnit, "allMeasureUnitTypesArr", r.data);
      this.$set(
        this.dialogMeasureUnit,
        "selectedMeasureUnitType",
        item.measureUnitTypeID
      );
      this.$set(this.dialogMeasureUnit, "price", item.price);
      this.$set(this.dialogMeasureUnit, "priceInPromo", item.priceInPromo);
      this.$set(this.dialogMeasureUnit, "isInPromo", item.isInPromo);
      this.$set(this.dialogMeasureUnit, "isEnabled", item.isEnabled);
      this.$set(
        this.dialogMeasureUnit,
        "IsConversionShown",
        item.isConversionShown
      );
      this.checkConversion();
      if (item.conversionInKg) {
        this.dialogMeasureUnit.conversion = item.conversionInKg;
      }
    } else {
      this.$set(this.dialogMeasureUnit, "title", "Nuova unità di misura");
      this.$set(this.dialogMeasureUnit, "button", "aggiungi");
      this.$set(
        this.dialogMeasureUnit,
        "allMeasureUnitTypesArr",
        r.data.filter((el1) =>
          this.measureUnitTypesArr.every(
            (el2) => el1.id !== el2.measureUnitTypeID
          )
        )
      );
      this.$set(this.dialogMeasureUnit, "conversion", null);
      this.$set(this.dialogMeasureUnit, "selectedMeasureUnitType", null);
      this.$set(this.dialogMeasureUnit, "price", null);
      this.$set(this.dialogMeasureUnit, "priceInPromo", null);
      this.$set(this.dialogMeasureUnit, "isInPromo", false);
      this.$set(this.dialogMeasureUnit, "isEnabled", true);
    }
  }

  private onDelete(item: MeasureUnitTypesByProduct): void {
    this.measureUnitTypeToDelete = item.measureUnitTypeID;
    this.onDeleteMessage =
      "Sei sicuro di volere eliminare l'unità di misura " +
      item.measureUnitTypeName.toUpperCase() +
      "da questo listino ( id: " +
      item.measureUnitTypeID +
      ") ?";
    this.deleteDialog = true;
  }

  private onCheckChange(item: MeasureUnitTypesByProduct) {
    this.measureUnitTypesArr = this.measureUnitTypesArr.map(
      (measureUnitType: MeasureUnitTypesByProduct) => {
        if (measureUnitType.measureUnitTypeID == item.measureUnitTypeID) {
          measureUnitType.isDefault = true;
        } else {
          measureUnitType.isDefault = false;
        }
        return measureUnitType;
      }
    );
  }

  private deleteMeasureUnit(): void {
    const idx = this.measureUnitTypesArr.findIndex(
      (el) => el.measureUnitTypeID == this.measureUnitTypeToDelete
    );
    if (idx > -1) {
      this.measureUnitTypesArr.splice(idx, 1);
    } else {
      snackbarModule.showSnackbar({
        message: "Non è possibile eliminarr questa unità di misura",
        type: "error",
      });
      setTimeout(() => snackbarModule.hideSnackbar(), 2000);
    }
    this.deleteDialog = false;
  }

  private saveMeasureUnitType(): void {
    const isValid = (
      this.$refs.measureUnitForm as Vue & { validate: () => boolean }
    ).validate();
    this.valid = isValid;
    if (isValid) {
      if (this.dialogMeasureUnit.isEdit) {
        const m = this.measureUnitTypesArr.find(
          (el) =>
            el.measureUnitTypeID ==
            this.dialogMeasureUnit.selectedMeasureUnitType
        );
        if (m) {
          this.$set(m, "price", this.dialogMeasureUnit.price);
          this.$set(m, "priceInPromo", this.dialogMeasureUnit.priceInPromo);
          this.$set(m, "isEnabled", this.dialogMeasureUnit.isEnabled);
          this.$set(m, "isInPromo", this.dialogMeasureUnit.isInPromo);
          this.$set(m, "conversionInKg", this.dialogMeasureUnit.conversion);
          let photoIdx = this.dialogMeasureUnit.checks.indexOf(true);
          if (photoIdx != -1) {
            this.$set(m, "photoID", this.dialogMeasureUnit.photos[photoIdx].id);
          } else {
            this.$set(m, "photoID", null);
          }
          this.$set(
            m,
            "isConversionShown",
            this.dialogMeasureUnit.IsConversionShown
          );
        }
      } else {
        let newMeasureUnitType = {} as MeasureUnitTypesByProduct;
        this.$set(newMeasureUnitType, "measureUnitTypeName", null);
        const selected = this.dialogMeasureUnit.allMeasureUnitTypesArr.find(
          (el) => el.id == this.dialogMeasureUnit.selectedMeasureUnitType
        );
        if (selected) {
          this.$set(
            newMeasureUnitType,
            "measureUnitTypeName",
            selected.measureUnitTypeName
          );
        }
        this.$set(
          newMeasureUnitType,
          "isEnabled",
          this.dialogMeasureUnit.isEnabled
        );
        this.$set(
          newMeasureUnitType,
          "isInPromo",
          this.dialogMeasureUnit.isInPromo
        );
        this.$set(
          newMeasureUnitType,
          "measureUnitTypeID",
          this.dialogMeasureUnit.selectedMeasureUnitType
        );
        this.$set(newMeasureUnitType, "price", this.dialogMeasureUnit.price);
        this.$set(
          newMeasureUnitType,
          "priceInPromo",
          this.dialogMeasureUnit.priceInPromo
        );
        this.$set(
          newMeasureUnitType,
          "conversionInKg",
          this.dialogMeasureUnit.conversion
        );
        this.$set(newMeasureUnitType, "isDefault", false);
        let photoIdx = this.dialogMeasureUnit.checks.indexOf(true);
        if (photoIdx != -1) {
          this.$set(
            newMeasureUnitType,
            "photoID",
            this.dialogMeasureUnit.photos[photoIdx].id
          );
        } else {
          this.$set(newMeasureUnitType, "photoID", null);
        }
        this.$set(
          newMeasureUnitType,
          "isConversionShown",
          this.dialogMeasureUnit.IsConversionShown
        );
        this.measureUnitTypesArr.push(newMeasureUnitType);
      }
      this.$set(this.dialogMeasureUnit, "conversion", null);
      this.$set(this.dialogMeasureUnit, "show", false);
    }
  }

  private changeSelectedPhoto(urlIdx: number): void {
    if (this.dialogMeasureUnit.checks.every((check) => check == false)) return;
    this.dialogMeasureUnit.checks = this.dialogMeasureUnit.checks.map(
      () => false
    );
    this.dialogMeasureUnit.checks[urlIdx] = true;
  }

  private callValidate(): void {
    (this.$refs.measureUnitForm as Vue & { validate: () => void }).validate();
  }
}
